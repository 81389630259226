<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
              <div class="col-lg-4 col-md-6 ml-auto mr-auto">
                <card type="login">
                  <h3 slot="header" class="header text-center">Login</h3>



                  <fg-input name="email" v-model="form.email" addon-left-icon="nc-icon nc-single-02" placeholder="Email" v-validate="modelValidations.email" :error="getError('email')"></fg-input>

                  <fg-input name="password" v-model="form.password" addon-left-icon="nc-icon nc-key-25" placeholder="Password" type="password" v-validate="modelValidations.password" :error="getError('password')"></fg-input>

                  <br>

                  <div class="alert alert-danger" v-if="errorMsg">{{ errorMsg }}</div>

                  <p-button slot="footer" type="warning" round block class="mb-3" @click.prevent="validate">Log in</p-button>
                </card>
              </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Card, Checkbox, Button} from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    computed: {
      ...mapGetters({ currentUser: 'currentUser' })
    },
    // created () {
    //   this.checkCurrentLogin()
    // },
    // updated () {
    //   this.checkCurrentLogin()
    // },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      // checkCurrentLogin () {
      //   if (this.currentUser) {

      //     console.log(this.currentUser)
      //     this.$router.replace(this.$route.query.redirect || '/admin/overview')
      //   }
      // },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          // this.$emit('on-submit', this.registerForm, isValid)
          if(isValid){
            this.login()
          }
        })
      },
      login () {
        this.$http.post('/auth/login', { email: this.form.email, password: this.form.password })
          .then(request => this.loginSuccessful(request))
          .catch(() => this.loginFailed())
      },
      loginSuccessful (req) {
        if (!req.data.token) {
          this.loginFailed()
          return
        }
        this.errorMsg = false
        // localStorage.token = req.data.token
        localStorage.setItem('token', req.data.token)
        localStorage.setItem('userId', req.data.userId)

        this.$store.dispatch('login')
        this.$store.dispatch('getUserProductions')
        this.$router.replace(this.$route.query.redirect || '/admin/overview')
      },
      loginFailed () {
        this.errorMsg = 'Your email or password is incorrect'
        this.$store.dispatch('logout')
        delete localStorage.token
        delete localStorage.vuex
      }
    },
    data() {
      return {
        errorMsg: false,
        form: {
          email: '',
          password: '',

        },
        modelValidations: {
          email: {
            required: true,
            email: true
          },
          password: {
            required: true,
            min: 5
          }
        }
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
