import JwtDecode from 'jwt-decode'

export default class User {
  static from (token) {
    try {
      let obj = JwtDecode(token)

      // console.log(obj)

      return new User(obj)
    } catch (_) {
      // return null
      return new User({ user_id:'', admin:'', email:'', name:'', user_int:'' })
    }
  }

  constructor ({ user_id, admin, email, name, user_int }) {

    // console.log('User constructor')
    this.userId = user_id // eslint-disable-line camelcase
    this.admin = admin
    this.email = email
    this.name = name
    this.userInt = parseInt(user_int)  // the use id as int
  }

  get isAdmin () {
    return this.admin
  }
}
