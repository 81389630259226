import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'
import Logout from 'src/components/Dashboard/Views/Pages/Logout.vue'



// Calendar
import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'


// Maketime src/components/maketime/common/Productions.vue
const Productions = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Common/Productions.vue')
const Production = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ProductionForms/Production.vue');

const Clients = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Sales/Clients.vue')
const Contacts = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Sales/Contacts.vue')

const Salesboard = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Sales/Salesboard.vue')
const Promotions = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Sales/Promotions.vue')

const SalesPipeline = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Sales/SalesPipeline.vue')
const Booking = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/SalesForms/Booking.vue');
const Contact = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/SalesForms/Contact.vue');
const ProductionNotes = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Sales/ProductionNotes.vue')
const Note = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/SalesForms/Note.vue');
const Participants = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Production/Participants.vue')
const Client = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/SalesForms/Client.vue');
const Participant = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ProductionForms/Participant.vue');

const AccountClients = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Accounts/Clients.vue')
const AccountBookings = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Accounts/Bookings.vue')
const ParticipantImport = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Production/ParticipantImport.vue')



// test component
const TableTemplate = () => import(/* webpackChunkName: "maketime" */ 'src/components/Maketime/Sales/TableTemplate.vue')


let productionsPage = {
  path: '/productions',
  name: 'Productions',
  component: Productions
}

let salesMenu = {
  path: '/sales',
  component: DashboardLayout,
  redirect: '/sales/clients',
  children: [
    {
      path: 'clients',
      name: 'Clients',
      component: Clients
    },
    {
      path: 'contacts',
      name: 'Contacts',
      component: Contacts
    },
    {
      path: 'salesboard',
      name: 'Salesboard',
      component: Salesboard
    },
    {
      path: 'promotions',
      name: 'Promotions',
      component: Promotions
    },
    {
      path: 'tabletemplate',
      name: 'TableTemplate',
      component: TableTemplate
    },
    {
      path: 'salespipeline',
      name: 'Sales Pipeline',
      component: SalesPipeline
    },
    {
      path: 'productionnotes',
      name: 'Production Notes',
      component: ProductionNotes
    }

  ]
}

let productionMenu = {
  path: '/production',
  component: DashboardLayout,
  redirect: '/production/participants',
  children: [
    {
      path: 'participants',
      name: 'Participants',
      component: Participants
    },
    {
      path: 'participantimport',
      name: 'ParticipantImport',
      component: ParticipantImport
    }
  ]
}

let accountsMenu = {
  path: '/accounts',
  component: DashboardLayout,
  redirect: '/accounts/clients',
  children: [
    {
      path: 'clients',
      name: 'AccountClients',
      component: AccountClients
    },
    {
      path: 'bookings',
      name: 'Bookings',
      component: AccountBookings
    }
  ]
}

//
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [

    {
      path: 'production/:id',
      name: 'Production',
      component: Production,
      meta: { requiresAuth: true, role: 'admin' }
    },
    {
      path: 'booking/:id',
      name: 'Booking',
      component: Booking,
      meta: { requiresAuth: true, role: 'admin' }
    },
    {
      path: 'contact/:id',
      name: 'Contact',
      component: Contact,
      meta: { requiresAuth: true, role: 'admin' }
    },
    {
      path: 'note/:id',
      name: 'Note',
      component: Note,
      meta: { requiresAuth: true, role: 'admin' }
    },
    {
      path: 'client/:id',
      name: 'Client',
      component: Client,
      meta: { requiresAuth: true, role: 'admin' }
    },
    {
      path: 'participant/:id',
      name: 'Participant',
      component: Participant,
      meta: { requiresAuth: true, role: 'admin' }
    }

  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}


let logoutPage = {
  path: '/logout',
  name: 'Logout',
  component: Logout
}


let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      }
      ,{
        path: 'productions',
        name: 'Productions',
        component: Productions
      }
    ]
  },
  // MM
  //productionsPage,
  salesMenu,
  productionMenu,
  accountsMenu,
  // MM

  formsMenu,
  pagesMenu,
  loginPage,
  logoutPage,
  lockPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
