/* global localStorage */
import Vue from 'vue'
import User from '@/models/User'
import * as MutationTypes from './mutation-types'

const state = {
  gridStates: []
}

const mutations = {

  [MutationTypes.SET_GRID_STATE] (state, serverParams ) {

    // console.log('SET_GRID_STATE', serverParams)
    state.gridStates[serverParams.gridName] = serverParams
    // console.log('SET_GRID_STATE', state.gridStates)

  }
}

const getters = {

  gridStates (state) {
    return state.gridStates
  },
  // singular
  gridState (state, gridName) {
    // TODO this needs to return a function
    return state.gridStates
  }

}

const actions = {

  setGridState ({ commit }, serverParams) {
    // console.log('setGridState', serverParams  )
    commit(MutationTypes.SET_GRID_STATE, serverParams )
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
