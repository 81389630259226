/* global localStorage */
import Vue from 'vue'
import User from '@/models/User'
import * as MutationTypes from './mutation-types'

const state = {
  // user: User.from(localStorage.JWT),
  user: User.from(localStorage.token),
  userChoices: {
    tableTheme: 'nocturnal',
    userSelectedProduction: 0,    // the user's selected production
    userSelectedProductionInt: 0, // as int
    userSelectedProductionName: 'No Production Selected',
    userSelectedProductionCcy: '',
    userProductions: [
      {
        userDefault: 0,
        recordId: 0,
        id: '',
        url: '#',
        name: 'You have no selected productions',
        ccy: '',
        productionInt: 0  // id as int
      }
    ]
  }
}

const mutations = {
  [MutationTypes.LOGIN] (state) {
    // console.log('restoring ')
    state.user = User.from(localStorage.token)

    // let userChoices = localStorage.getItem('userChoices')
    // if (userChoices !== null) {
    //   state.userChoices = userChoices
    // }
    // console.log('LOGIN fetched user state.userChoices:', state.userChoices)
  },
  [MutationTypes.LOGOUT] (state) {

    console.log ('MutationTypes.LOGOUT')

    state.user = null
    state.userChoices.userSelectedProduction = null
    state.userChoices.userProductions = []
  },
  [MutationTypes.SET_USER_PRODUCTION] (state, userSelectedProduction) {
    console.log('userSelectedProduction', userSelectedProduction.id)
    state.userChoices.userSelectedProduction = userSelectedProduction.id
    state.userChoices.userSelectedProductionName = userSelectedProduction.name
    state.userChoices.userSelectedProductionCcy = userSelectedProduction.ccy
    state.userChoices.userSelectedProductionInt = parseInt(userSelectedProduction.productionInt)


  },
  [MutationTypes.ADD_USER_PRODUCTION] (state, userSelectedProduction) {

    let newProd = {
      "userDefault":0,
      "recordId":0,
      "id": userSelectedProduction.productionId,
      "url":"#",
      "name":userSelectedProduction.productionName,
      "ccy":userSelectedProduction.ccy,
      "productionInt": userSelectedProduction.productionInt
    }

    // console.log('addSelectedProduction', newProd)
    state.userChoices.userProductions.push(newProd)
  },
  [MutationTypes.REMOVE_USER_PRODUCTION] (state, userSelectedProduction) {

    // console.log('removeSelectedProduction', userSelectedProduction.id)

    for(var i = state.userChoices.userProductions.length - 1; i >= 0; i--) {
      if(state.userChoices.userProductions[i].id === userSelectedProduction.productionId) {
        state.userChoices.userProductions.splice(i, 1)
        break
      }
    }
  },
  [MutationTypes.LOAD_USER_PRODUCTIONS] (state, userId) {
    // console.log('LOAD_USER_PRODUCTIONS')

    Vue.axios.get('common/user/production/' + userId)
        .then((response) => {
          // find the user's selected production
          response.data.forEach(function (production, index) {
                       // console.log(production)

            if (production.userDefault) {
              state.userChoices.userSelectedProduction = production.id
              state.userChoices.userSelectedProductionName = production.name
              state.userChoices.userSelectedProductionCcy = production.ccy
              state.userChoices.userSelectedProductionInt = parseInt(production.productionInt)
              // console.log(state.userChoices)
            }
          })

          state.userChoices.userProductions = response.data

          // console.log('Loaded user data')
          // console.log(state.userChoices)

        })
        .catch((error) => {
          // alert('Failed to load user productions')
          console.log(error)
        })
  }
}

const getters = {
  tableTheme (state) {
    return state.userChoices.tableTheme
  },
  currentUser (state) {
    return state.user
  },
  userSelectedProduction (state) {
    return state.userChoices.userSelectedProduction
  },
  userSelectedProductionInt (state) {
    return state.userChoices.userSelectedProductionInt
  },
  userProductions (state) {
    return state.userChoices.userProductions
  },
  userSelectedProductionName (state) {
    return state.userChoices.userSelectedProductionName
  },
  userSelectedProductionCcy (state) {
    return state.userChoices.userSelectedProductionCcy
  }

}

const actions = {


  clearOnLogout ( { commit } ) {
    // commit('CLEAR_DOWN')
    commit(MutationTypes.LOGOUT)
  },
  login ({ commit }) {
    commit(MutationTypes.LOGIN)
  },
  logout ({ commit }) {
    commit(MutationTypes.LOGOUT)
  },
  getUserProductions ({ state, commit, rootState }) {
    // get the userId from another store module
    let userId = rootState.auth.user.userId
    commit(MutationTypes.LOAD_USER_PRODUCTIONS, userId)
  },
  setUserProduction ({ commit }, userSelectedProduction) {
    commit(MutationTypes.SET_USER_PRODUCTION, userSelectedProduction)
  },
  addUserProduction ({ commit }, userSelectedProduction) {
    commit(MutationTypes.ADD_USER_PRODUCTION, userSelectedProduction)
  },
  removeUserProduction ({ commit }, userSelectedProduction) {
    commit(MutationTypes.REMOVE_USER_PRODUCTION, userSelectedProduction)
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
