import Vue from 'vue'
import {app} from '../main'
import VueAxios from 'vue-axios'

import axios from './axios'
// import vue from '../main';
// import router from '../../router'

Vue.use(VueAxios, axios)

axios.interceptors.request.use((config) => {

  const token = localStorage.token // localStorage.getItem("token")
  // const token = localStorage.JWT // localStorage.getItem("token")
  config.headers.Authorization = `Bearer ${token}`
  return config
})

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Do something with response error
  console.log('intercepted', error)
  if (error.response.status === 401) {
    console.log('unauthorized, logging out ...')
    // auth.logout()
    // vue.$router.replace('/login')

    delete localStorage.token
    delete localStorage.vuex

    app.$router.push({  name: 'Login'})
  }
  return Promise.reject(error.response)
})
