import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueNotify from 'vue-notifyjs'
import VeeValidate from 'vee-validate'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';

// MM
import authMiddleware from './auth/applyauth';
import axios from './vue-axios';
import store from './store'
import moment from "moment";
import VueMomentJS from "vue-momentjs";
import VueGoodTablePlugin from 'vue-good-table';
import VueGoodWizard from 'vue-good-wizard';

import 'vue-progress-path/dist/vue-progress-path.css'
import VueProgress from 'vue-progress-path'

import accounting from 'accounting'

Vue.filter('formatMoney', function(val, symbol){
  return accounting.formatMoney(val, symbol)
})

// router setup
import routes from './routes/routes'

// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/element_variables.scss'
import './assets/sass/demo.scss'

import sidebarLinks from './sidebarLinks'
// plugin setup
Vue.use(VueRouter)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use(VueProgress)



Vue.use(VueGoodWizard);


// Vue.use(VeeValidate)
// Vue.use(VeeValidate, {
//   events: 'change'
// })

Vue.use(VeeValidate, {
  fieldsBagName: 'vvFields'
});

Vue.use(VueMomentJS, moment)
Vue.use(VueGoodTablePlugin)

// this import didn't work in the build version so the contents are copied into
// /maketimemedia/maketimemedia/maketime-client/src/assets/sass/demo.scss
// import 'vue-good-table/dist/vue-good-table.css'


locale.use(lang)

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active'
})

Vue.config.errorHandler = function (err, vm, info)  {
  console.log('[Global Error Handler]: Error in ' + info + ': ' + err );
  console.log(vm)
}
window.onerror = function (msg, url, line, col, error) {
  //code to handle or report error goes here
  console.log('[window Error Handler]:  ' + msg + ': ' + url + ':' + line + ':' + col + ':' + error);
}

authMiddleware(router);

initProgress(router);

/* eslint-disable no-new */
export const app = new Vue({
  el: '#app',
  render: h => h(App),
  router,
  axios,      // accessed via this.$http
  store
})

// export default to enable access to the vue instance in js files (e.g. applyauth)
