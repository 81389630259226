// import Notify from 'vue-notifyjs'
import vue from '../main';
import jwtDecode from 'jwt-decode';

function redirectToMainPage(next) {
  // You can display a notification here if needed
  vue.$notify({
    message: 'Sorry you are not authorised to view this page',
    type: 'primary'
  })
  return next({path: '/'});
}

/**
 * Middleware to check if user has the correct right to access a certain page.
 * Checks are performed based on route meta fields `meta: { requiresAuth: true }`.
 * @param {object} router Vue router instance
 */
export default function authMiddleware(router) {
  router.beforeEach((to, from, next) => {
    // check for the routes that have `requiresAuth: true`
    let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (!requiresAuth) {
      return next();
    }
    let token = localStorage.getItem('token');
    if (!token) {
      return next({path: '/login'});
    }
    try {


      let decodedToken = jwtDecode(token);

      console.log('applyauth token', decodedToken)

      let { user_id, role } = decodedToken;
      let { role: metaRole } = to.meta;
      let userId = localStorage.getItem('userId');

      console.log('applyauth: userId:', userId)
      console.log('applyauth: user_id:', user_id)
      console.log('applyauth: role:', role)

      if (user_id === userId) {

        console.log('applyauth: user:', user_id)

        if(role && metaRole && role !== metaRole){

          console.log('applyauth: role not present')
          return redirectToMainPage(next);
        }
        return next()
      } else {
        // console.log('applyauth: redirectToMainPage', next)
        redirectToMainPage(next);
      }
    } catch (err) {
      redirectToMainPage(next);
    }
  })
}
