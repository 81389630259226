/* global localStorage */

import axios from 'axios'
import * as CONSTS from '../consts/constants'

// const API_URL = process.env.API_URL || 'https://services.maketimemedia.com'
const API_URL = process.env.API_URL || CONSTS.MM_BASE_URL   //'http://localhost:8080'

export default axios.create({
  baseURL: API_URL,
  timeout: 0,  // no timeout
  headers: {
    'Content-Type': 'application/json'
  }
})
