<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">

        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ userSelectedProductionName }}</a>
    </div>

    <template slot="navbar-menu">

      <drop-down position="right"
                   direction="none">
      <p-button slot="title"
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                type="primary"
                block
                round>
        My Productions
      </p-button>
      <a class="dropdown-item"
            v-for="production in userProductions"
            :key="production.id"
            :value="production.name"
            @click="setUserProduction(production)">
            {{production.name}}</a>
    </drop-down>


      <!-- <drop-down tag="div" title="My Productions"
                   position="right"
                   direction="none">
        <a class="dropdown-item" href="#"
            v-for="production in userProductions"
            :key="production.id"
            :value="production.name"
            @click="setUserProduction(production)">
            {{production.name}}</a>
      </drop-down> -->

      <!-- <drop-down
                   icon="nc-icon nc-settings-gear-65"
                   tag="li"
                   title="My Productions"
                   position="right"
                   direction="none"
                   class="nav-item btn-rotate dropdown">
          <a slot="title"
             slot-scope="{isOpen}"
             class="nav-link dropdown-toggle"
             data-toggle="dropdown"
             aria-haspopup="true"
             :aria-expanded="isOpen">
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">My Productions</span>
            </p>
          </a>

          <a class="dropdown-item" href="#"
            v-for="production in userProductions"
            :key="production.id"
            :value="production.name"
            @click="setUserProduction(production)">
            {{production.name}}</a>
        </drop-down> -->

      <!--
          <b-nav-item-dropdown text="My Productions" right>
                    <b-dropdown-item v-for="production in userProductions"
                      :key="production.id"
                      :value="production.name"
                      @click="setUserProduction(production)">
                      {{production.name}}
                      </b-dropdown-item>
                </b-nav-item-dropdown>
      -->

      <!-- <form>
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link btn-magnify" href="#pablo">
            <i class="nc-icon nc-layout-11"></i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        <drop-down icon="nc-icon nc-bell-55" tag="li"
                   position="right"
                   direction="none"
                   class="nav-item btn-rotate dropdown">
          <a slot="title"
             slot-scope="{isOpen}"
             class="nav-link dropdown-toggle"
             data-toggle="dropdown"
             aria-haspopup="true"
             :aria-expanded="isOpen">
            <i class="nc-icon nc-bell-55"></i>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </drop-down>
        <li class="nav-item">
          <a class="nav-link btn-rotate" href="#pablo">
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li>
      </ul> -->
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      Navbar,
      NavbarToggleButton
    },
    computed: {
      ...mapGetters(['currentUser','userProductions', 'userSelectedProductionName'])
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false
      }
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },
      setUserProduction: function (production) {
        this.$store.dispatch('setUserProduction', production)
        console.log('current route:', this.$router.currentRoute)
        console.log(production)

     // PUT /common/user/@userId/production/@productionId'
        let vm = this
        this.$http.put( 'common/user/' + this.currentUser.userId + '/production/'+production.id, {} )
            .then(function (response) {

              if (response.data.apiresponse.status !== 'success'){
                vm.$notify({
                  message: 'There was a problem saving your default production. Please try again. Error code: ' + response.data.apiresponse.code,
                  type: 'warning'
                })
              }
            })
            .catch(function (error) {
              console.log(error)
              vm.$notify({
                message: 'There was a problem saving your default production. Please try again.',
                type: 'warning'
              })
            })



        // refresh the current page
        // this.$router.go(this.$router.currentRoute)
        // this.$router.replace('/dashboard')
      }
    }
  }

</script>
<style>

</style>
