<template>
  <div class="login-page">
    <app-navbar></app-navbar>

    <div class="wrapper wrapper-full-page">
      <div >
      <!-- <div class="full-page login-page section-image">   -->
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">

        <div class="container">
          <div class="col-lg-4 col-md-6 ml-auto mr-auto">
            <card type="login">
              <h3 slot="header" class="header text-center">Logout</h3>
              <div class="alert alert-danger">You are now logged out.</div>

              <router-link to="/login">Login</router-link>
            </card>
          </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/background-2.jpg) "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Checkbox, Button } from "src/components/UIComponents";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" })
  },
  created() {
    this.$store.dispatch("logout");
    this.clearOnLogout();
    delete localStorage.token;
    delete localStorage.vuex;
  },
  // updated () {
  //   this.checkCurrentLogin()
  // },
  methods: {
    ...mapActions(["clearOnLogout"]),
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },

    login() {
      console.log("pop");
    }
  },
  data() {
    return {};
  },
  beforeDestroy() {
    this.closeMenu();
  }
};
</script>
<style>
</style>
