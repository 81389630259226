import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import grid from './grid'
Vue.use(Vuex)
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  key: 'vuex',
  storage: window.localStorage
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    auth,
    grid
  }
})
